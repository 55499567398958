import React from 'react';
import { render } from 'react-dom';
import AsyncComponent from './AsyncComponent.jsx';

export default function Mount(selector, component, validProps = []) {
	const targets = document.querySelectorAll(selector);
	for (let i = (targets.length - 1); i >= 0; i--) {
		const elem = targets[i];

		const props = {};
		for (let i = 0; i < validProps.length; i++) {
			const attributeValue = elem.getAttribute(validProps[i]);
			if (attributeValue) {
				props[validProps[i]] = attributeValue;
			}
		}
		render(<AsyncComponent moduleProvider={component} {...props} />, elem);
	}
}

export function MountMap(selector, Component, validProps = []) {
	const targets = document.querySelectorAll(selector);
	for (let i = (targets.length - 1); i >= 0; i--) {
		const elem = targets[i];

		const props = {};
		for (let i = 0; i < validProps.length; i++) {
			const attributeValue = elem.getAttribute(validProps[i]);
			if (attributeValue) {
				props[validProps[i]] = attributeValue;
			}
		}
		render(<Component  {...props} />, elem);
	}
}

export function MountLoaded(selector, Component, validProps) {
	Mount(selector, () => Promise.resolve({ Component }), validProps);
}
