import Mount from 'xp-lib-brand/react/utils/mount.js';

const accordion = () => import(/* webpackChunkName: "accordion" */ './modules/accordion/accordion.jsx');
const chart = () => import(/* webpackChunkName: "chart" */ './modules/chart/chart.jsx');
const closeWarning = () => import(/* webpackChunkName: "closeWarning" */ './modules/close-warning/close-warning.jsx');
const comments = () => import(/* webpackChunkName: "comments" */ './modules/comments-container/comments-container.jsx');
const cta = () => import(/* webpackChunkName: "cta" */ './modules/cta/cta.jsx');
const figureImage = () => import(/* webpackChunkName: "figureImage" */ './modules/figure-image/figure-image.jsx');
const filterCheckbox = () => import(/* webpackChunkName: "figureImage" */ './modules/filter-checkbox/filter-checkbox.jsx');
const findRepresentative = () => import(/* webpackChunkName: "findRepresentative" */ './modules/find-representative/find-representative.jsx');
const footer = () => import(/* webpackChunkName: "footer" */ './modules/footer/footer.jsx');
const helpform = () => import(/* webpackChunkName: "helpform" */ './modules/help-form/help-form.jsx');
const producerDistrict = () => import(/* webpackChunkName: "producerDistrict" */ './modules/choose-producer-district/choose-producer-district.jsx');
const myProfileContactInfo = () => import(/* webpackChunkName: "myProfileContactInfo" */ './modules/my-profile-contact-info/my-profile-contact-info.jsx');
const pdfHistoryList = () => import(/* webpackChunkName: "pdfHistoryList" */ './modules/pdf-history-list/pdf-history-list.jsx');

Mount('.member-accordion-react', accordion, ['sectionId', 'btnTitle', 'text', 'className', 'childComponents']);
Mount('.react_chart_container', chart, ['chartId', 'tableHeadingOn', 'tableToShow', 'title', 'headingLvl', 'minHeight']);
Mount('.close-warning', closeWarning, ['warningId', 'btnText']);
Mount('.member-comments-react', comments, ['commentServiceUrl', 'articleId', 'encryptedId', 'articleTitle']);
Mount('.member-cta-button', cta, ['description', 'buttonText', 'destination', 'btnClass', 'gaTag', 'showArrowIcon', 'target']);
Mount('.help-form', helpform, ['h2Title', 'fieldVariant', 'sendMailServiceUrl', 'buttonText','upperInputText', 'lowerInputText', 'thankYouNote', 'recipient', 'subject']);
Mount('.figure-img-react', figureImage, ['className', 'src', 'alt', 'srcSet', 'caption', 'artist', 'showImageCaption', 'pictureSources']);
Mount('.filter-checkbox', filterCheckbox, ['filterName', 'filterDisplayName', 'filterId', 'filterParamsName', 'key']);
Mount('.member-find-representative', findRepresentative, ['findRepresentativeService']);
Mount('.member-footer-react', footer, ['classname', 'childrenComponents']);
Mount('.member-producer-district', producerDistrict, ['showCowSelector', 'showGoatSelector', 'showDistrictSelector', 'producerMyPageViewUrl', 'qProducerMyPageViewUrl']);
Mount('.member-contact-info', myProfileContactInfo, ['contactInfoStaticInfoEncoded', 'contactInfoObjEncoded', 'headerTitle', 'specificClass', 'submitUrl']);
Mount('.member-pdf-history-list', pdfHistoryList, ['historyListTitle', 'inHistoryList', 'stringNumToShowAtSameTime', 'tableView']);
